<template>
  <div id="navbar">
      <h5 class="fw-bold">ASOMBOMB - GUATEMALA</h5>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#navbar{
    background: rgba($color: #02353C, $alpha: 0.75);
    width: 100%;
    padding: 1rem;
    height: 4rem;
    position: absolute;
    top: 0;
    left: 0;
}
</style>