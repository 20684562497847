<template>
  <div>
    <sidebar/>
    <div id="main">
      <navbar/>
      <router-view/>
    </div>
  </div>
</template>

<script>
import  sidebar  from "@/components/sidebar";
import navbar from './components/navbar.vue';
export default {
  components:{ 
    sidebar, navbar
  }  
}
</script>

<style lang="scss">

</style>
