import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [  
  { path: '/', name: 'login', component: () => import('../views/login.vue')},

  { path: '/home', name: 'home', component: () => import('../views/home.vue')},
  { path: '/regions', name: 'regions', component: () => import('../views/regions.vue')},
  { path: '/regions/:id', name: 'stations', component: () => import('../views/stations/stations.vue')},
  
  { path: '/warehouses', name: 'warehouses', component: () => import('../views/warehouses/warehouses.vue')},
  { path: '/warehouses/:id/categories', name: 'caterogires', component: () => import('../views/warehouses/productCategories.vue')},
  { path: '/station/:id/assignWarehouses', name: 'assignwWarehouses', component: () => import('../views/stations/assignWarehouses.vue')},
  { path: '/products', name: 'products', component: () => import('../views/products.vue')},
  
  { path: '/providers', name: 'providers', component: () => import('../views/providers.vue')},
  { path: '/accounts', name: 'accounts', component: () => import('../views/accounts.vue')},
  
  { path: '/purchases', name: 'purchases', component: () => import('../views/purchases/purchases.vue')},
  { path: '/purchases/create', name: 'createPurchase', component: () => import('../views/purchases/createPurchase.vue')},
  
  { path: '/transfers', name: 'transfers', component: () => import('../views/transfers/transfersMenu.vue')},
  { path: '/transfers/knowledge', name: 'knowledge', component: () => import('../views/transfers/knowledges.vue')},
  
  { path: '/transfers/diverseProducts', name: 'diverseProducts', component: () => import('../views/transfers/diverseProducts.vue')},
  { path: '/transfers/detailsDiverseProduct/:id', name: 'detailsDiverseProduct', component: () => import('../views/transfers/detailsDiverseProduct.vue')},
  { path: '/transfers/detailsDiverseProductPreview/:id', name: 'detailsDiverseProductPreview', component: () => import('../views/transfers/detailsDiverseProductPreview.vue')},
  
  { path: '/transfers/contract', name: 'contract', component: () => import('../views/transfers/contract.vue')},
  { path: '/transfers/contractDetail/:id', name: 'contractDetail', component: () => import('../views/transfers/contractDetail.vue')},
  
  { path: '/transfers/listResponsability', name: 'listResponsability', component: () => import('../views/transfers/listResponsability.vue')},
  { path: '/transfers/responsabilityDetail', name: 'responsabilityDetail', component: () => import('../views/transfers/responsabilityDetail.vue')},
  { path: '/transfers/detailsResponsability/:id', name: 'detailsResponsability', component: () => import('../views/transfers/detailsResponsability.vue')},
  { path: '/transfers/detailsResponsabilityPreview/:id', name: 'detailsResponsabilityPreview', component: () => import('../views/transfers/detailsResponsabilityPreview.vue')},
] 

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
