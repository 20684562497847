<template>
  <main>
    <b-sidebar id="sidebar-1" shadow v-model="show" no-close-on-route-change  no-header-close>
        <div class="bg-dark p3" id="title">
            <img src="@/assets/logo.png" width="30" alt="AS"> <h5 class="ms-3 text-green-1">ASOMBOMB</h5>
        </div>

        <div style="margin-top:3rem; position:relative;" :key="reload">

            <template v-for="item in items" >
                <router-link :to="item.path" :key="item.id">
                    <div class="p-3 route" :class="item.active ? 'active':''">
                        <i :class="item.icon" class="me-3"></i> {{item.name}}
                    </div>
                </router-link> 
            </template>
        </div>
        
        
    </b-sidebar>

  </main>
</template>

<script>
export default {
watch: {
    $route(to, from) {
        try {
            this.items.forEach(element=> {element.active=false})                   
            let current = this.items.find(i=>i.path===to.path || to.path.includes(i.component))
            if (current) {
                current.active = true           
            }
            this.reload++            
        } catch (error) {
            console.error("error en ruta # ", error)
        }
    },
},
data(){
    return {
        show:true,
        reload:0,
        items:[
            {id:1, name: " Inicio", path: "/home", icon:"fas fa-home", component:"home" },
            {id:2, name: " Subestaciones", path: "/regions/1", icon:"fas fa-list", component:"stations" },
            // {id:2, name: " Regiones", path: "/regions", icon:"fas fa-list", component:"regions" },
            {id:3, name: " Almacenes", path: "/warehouses", icon:"fas fa-warehouse", component:"warehouses" },
            {id:4, name: " Productos", path: "/products", icon:"fas fa-cubes", component:"products" },
            {id:5, name: " Proveedores", path: "/providers", icon:"fas fa-users", component:"providers" },
            {id:6, name: " Renglones", path: "/accounts", icon:"fas fa-calculator", component:"accounts" },
            {id:7, name: " Facturas", path: "/purchases", icon:"fas fa-file-invoice", component:"purchases" },
            {id:8, name: " Traslados", path: "/transfers", icon:"fas fa-file-import", component:"transfers" },
        ]
    }
}
}
</script>

<style lang="scss" scoped>
#title{
    position: absolute;
    top:0; width: 100%;
    padding: 1rem;
    display: flex;
    height: 4rem !important;
    
}
.active{
    background: rgba($color: #fff, $alpha: 0.1);
}
</style>